$color-primary: #6d1054;
$color-secondary: #f07425;
$color-grey: #5a5c63;
$color-black: #000;
$color-white: #fff;

$bp-large: 75em; // 1200px
$bp-medium: 56.25em; // 900px
$bp-small: 31.25em; // 500px

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');

* {
	margin: 0;
	padding: 0;
	font-weight: 400;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
	font-size: 50%;
	scroll-behavior: smooth;
	scroll-padding-top: 30px;

	@media only screen and (min-width: $bp-large) {
		font-size: 50%;
		font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%
	}
}

body {
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	line-height: 1.6;
	font-size: 1.6rem;
	color: $color-black;
	min-height: 100vh;
}

@mixin constrain {
	margin: auto;
	width: 100%;
	max-width: 90%;

	@media only screen and (min-width: $bp-large) {
		max-width: 1200px;
	}
}

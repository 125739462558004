h1 {
	color: $color-grey;
	font-size: 3rem;
}

h2 {
	color: $color-grey;
	font-size: 2.4rem;
}

h3 {
	color: $color-grey;
	font-size: 2.6rem;
}

h4 {
	font-size: 2rem;
}

.main-header {
}

.main-header__wrapper {
	@include constrain();
	padding: 5rem 0;
	margin-bottom: 5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid rgba($color-grey, 0.2);

	img {
		width: 140px;
		height: 135px;
	}
}

.main-content__wrapper {
	@include constrain();
	padding-bottom: 5rem;
	margin-bottom: 5rem;
	display: grid;
	grid-template-columns: 1fr 350px;
	grid-gap: 4rem;
	align-items: start;
	border-bottom: 1px solid rgba($color-grey, 0.2);
}

.content {
	h2 {
		margin-bottom: 3rem;
	}

	h4 {
		margin-bottom: 1rem;
		color: $color-primary;
	}

	ul {
		list-style: none;
	}

	> div {
		margin-bottom: 5rem;
		padding-bottom: 5rem;
		border-bottom: 1px solid rgba($color-grey, 0.2);

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border: none;
		}
	}

	.videos {
		li {
			margin-bottom: 5rem;
			padding: 2rem;
			border: 1px solid rgba($color-grey, 0.2);
			border-radius: 0.5rem;
			box-shadow: 0 0 2rem rgba($color-grey, 0.1);

			&:last-child {
				margin-bottom: 0;
			}
		}

		video {
			width: 100%;
			border-radius: 0.5rem;
		}
	}

	.downloads {
		li {
			margin: 4rem 0;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		a {
			position: relative;
			display: inline-flex;
			align-items: center;
			line-height: 1;
			text-decoration: none;
			font-size: 2.4rem;
			color: $color-primary;
			border-radius: 0.5rem;
			transition: all 0.2s ease;

			&:hover {
				text-indent: 0.5rem;

				&:after {
					width: 100%;
				}

				svg {
					transform: rotate(-90deg);
					fill: $color-primary;
				}
			}

			&:after {
				content: '';
				display: block;
				position: absolute;
				width: 0;
				height: 3px;
				bottom: -1.25rem;
				background: $color-secondary;
				transition: all 0.2s ease;
			}

			svg {
				width: 2.5rem;
				height: 2.5rem;
				fill: $color-grey;
				margin-right: 1rem;
				transition: all 0.2s ease;
			}
		}
	}
}

.menu {
	position: sticky;
	top: 40px;

	> div {
		margin-bottom: 2.5rem;
		padding-bottom: 2.5rem;
		border-bottom: 1px solid rgba($color-grey, 0.2);

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border: none;
		}
	}

	ul {
		list-style: none;

		li {
			margin: 1.5rem 0;
		}

		a {
			position: relative;
			text-decoration: none;
			display: inline-block;
			padding: 0.25rem 0;
			color: $color-primary;
			font-size: 1.6rem;
			transition: all 0.2s ease;

			&:hover {
				text-indent: 5px;
				&:after {
					width: 100%;
				}
			}

			&:after {
				content: '';
				display: block;
				position: absolute;
				width: 0;
				height: 3px;
				bottom: -3px;
				background: $color-secondary;
				transition: all 0.2s ease;
			}
		}
	}

	h3 {
		a {
			display: flex;
			align-items: center;
			color: $color-grey;
			text-decoration: none;
		}
	}
}

.main-footer__wrapper {
	color: $color-grey;
	margin-bottom: 5rem;
	text-align: center;
	font-size: 1.2rem;

	a {
		color: $color-black;
	}
}
